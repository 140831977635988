.sidebar{
	background-color: #dddddd;
	//padding: 4% 5%;
	padding: 40px 5%;
}

.sidebar h3 {
	color: $bhl-red;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}

.sidebar a{
	color: #444444;
}

#main-sidebar{
	display: none;
}

#secondary-sidebar{
	display: block;
}

.sidebar ul{
	list-style: none;
	padding-left: 2px;
}

.sidebar ul li{
	list-style-position: inside;
    text-indent: -1em;
	padding-left: 2em; 
}

.sidebar ul li:before{ 
	content:"•"; font-size:10pt; 
	padding-right: 8px;
}

.sidebar-container{


}

/* Standard Media Queries */
/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
	#main-sidebar{
		display: block;
		height: 100%;
		margin-bottom: -99999px;
  	    padding-bottom: 99999px;
	}
	#secondary-sidebar{
		display: none;
	}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 
	.sidebar{

	}

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}
