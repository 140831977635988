.footer-container{
	background-color: $bhl-blue;
}

.footer-widget-area h3{
	color: $bhl-grey;
	font-weight: $font-weight-bolder;
	text-transform: uppercase;
	text-align: center;
}

.footer-widget-area{
	padding: 35px;
}

.footer-widget-2, .footer-widget-3{
	color: $white;
	font-size: font-size-med;
	text-align: center;
	text-transform: uppercase;
	line-height: 1.5em;
}

.footer-widget-4 {
	color: $white;
	font-size: font-size-sm;
	text-align: center;
	line-height: 1.2em;
	/* letter-spacing: 2px; */
	font-size: 12px;
}



/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
	
	.footer-widget-1, .footer-widget-2 {
		padding-top: 30px;
	}

	.footer-widget-2, .footer-widget-3{
		text-align: left;
	}

	.footer-widget-area h3{
		text-align: left;
	}
	#footer-social{
		margin-top: -15px;
	}

	.footer-img{
		margin-top: 0px !important;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 
	#menu-social-nav-footer{
		float: left !important;
	}

	.footer-widget-1, .footer-widget-2, .footer-widget-3 {
		padding-top: 35px;
	}

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}