.main-bhl-post{
	/* padding: 5% 2%; */
	padding:0 0 5% 0;
}

.post-single{
	padding-left: 20px !important;
	margin-top: 40px;
}

article.post a{
	color: $bhl-blue;
	
}
article.post a:hover{
	color: $bhl-grey;
}

.main-bhl-post {
	color: $darktext;
}

.entry-summary, .entry-content {
	color: $darktext;
	/* letter-spacing: 2px; */
	margin: 2% 0;
}

.entry-summary a, .entry-content a, .main-bhl-post a {
	color: $bhl-red !important;
	font-weight: $font-weight-bolder;
}

.entry-summary a:hover, .entry-content a:hover, .main-bhl-post a:hover {
	color: $bhl-grey !important;
}

.bhl-community-content {
	padding: 0px 25px;
}

.bhl-community-img{
	background: url("../images/bg_community.jpg");
	background-size: cover;
    overflow: hidden; 
}

.bhl-community-img-placeholder{
	min-height: 1100px;
}

.entry-title a{
	color: $bhl-blue !important;
}

.content{
	height: 100%;
	overflow: hidden;
}

.quote{
	width: 80%;
	padding: 2%;
	margin-left: 2%;

}

/* Standard Media Queries */
/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
	article.post{
		padding: 0%;
	}
	.post-single{
		padding-left: 45px !important;
		margin-top: 40px;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 
.bhl-community-content {
	padding: 0px 45px;
}
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}
