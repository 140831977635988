@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');

html {
	background-color: $white;	
}
body {
	/*font-family: $font-stack;*/
	font-family: 'Montserrat', sans-serif;
	overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
	font-family: 'Montserrat', sans-serif;
	color: $bhl-blue;
	margin-top: 0px;

}


h1 {
	font-size: $font-size-lrg;
}


h1.entry-title{
	text-transform: uppercase;
	font-weight: $font-weight-bolder;

}

h2.entry-title{
	line-height: .9;
}

a {
	font-family: 'Montserrat', sans-serif;
}

#logo-main{
	width: 60px;
	height: 60px;
}
.container, .container-fluid{
	padding: 0px;
}

.clear {
	clear: both;
}

.no-pad{
	padding: 0px !important;
}


/* Standard Media Queries */
/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}