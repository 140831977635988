.page-header{
	border-bottom: none;
	margin-bottom: 0px;
}

.page-header h1{
	text-align: center;
	text-transform: uppercase;
	/* letter-spacing: 2px */; 
	font-weight: $font-weight-bolder;
}


.main-bhl{
	padding: 0px !important;

}

.frontpage-content-banner{
	background-image: 	
	  radial-gradient(
      		#237aab,
      		$bhl-blue
    	);
	 padding: 8%;

}

.frontpage-content{
	color: $white;
	font-size: $font-size-xs;
	text-align: center;
}


.bhl-year{
	color: $bhl-red;
	padding: 10px 0;
	font-size: $font-size-med;
	font-weight: $font-weight-boldest;
	/* letter-spacing: 2px */;
	text-align: center;
}

.frontpage-content h3{
	color: $bhl-red;
	font-size: font-size-xl;
	font-weight: $font-weight-boldest;
	/* letter-spacing: 2px */;
	width: 45%;
}


.row-even, .row-odd{
	color: $bhl-blue;
	padding: 3%;
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.row-even{
	background-color: $white;

}

.row-odd{
	background-color: $lightgrey;
}

.frontpage-img{
	margin: auto;
	padding-bottom: 3%;
}


.btn-learn{
	text-align: center;
}

.btn-learnmore{
	background-color: $white !important;
	font-weight: $font-weight-bolder;
	font-size: $font-size-xs;
	color: $bhl-blue;
	text-transform: uppercase;
	/* letter-spacing: 2px */;
	margin: 20px;
}



.btn-learnmore:hover {
	background-color: $odd !important;
	color: $bhl-blue;
}

.btn-red{
	color: $bhl-red;
}


.slider-logo{
	min-height: 150px;
	padding: 2% 0%;
}


.bhl-video{
	text-align: center;
}



.bhl-contact-content {
	font-size: $font-size-med;
	color: $darktext;
	text-align: center;
	padding-bottom: 40px;
}




.bhl-contact-number{
	margin-top: 25px;
	color: $bhl-red;
}

 .bhl-contact-map{
 	height: 100%;
 	min-height: 400px;
 	max-height: 400px;
 	padding: 0px !important;
 	float: right;
 }

.bhl-contact-map iframe {
	min-height: 400px;
	width: 100%;
}

.frontpage-widget-left-container{
	background: url("../images/bg_bubbles.jpg");
	background-size: cover;

}

.frontpage-widget-right-container{
	background: url("../images/bg_splash.jpg");
	background-size: cover;
}

.frontpage-widget-left-container, .frontpage-widget-right-container{
	padding-left: 0px;
}

.frontpage-widget-left, .frontpage-widget-right{
	padding:25px;
	font-size: $font-size-xl;
	font-weight: $font-weight-bolder;
	text-align: center;
}

.frontpage-widget-left{
	color: $white;
}

.frontpage-widget-right{
	color: $darkgrey;
}

#gMap {
        height: 400px;
        width: 100%;
       }


.bhl-careers-content{
	padding: 0px 25px;
	text-align: center;
}

.bhl-careers-img{
	background: url("../images/bg_careers.jpg");
	background-size: cover;
    overflow: hidden; 
}

.bhl-careers-img-placeholder{
	min-height: 468px;
}

.page-subhead{
	color: $darktext;
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
	/* letter-spacing: 2px */;
	margin-top: -10px;
	margin-bottom: 25px;

}

ul.bhl-careers-ul{
	list-style: none;
	padding-left: 0px;
}

.bhl-about-slider, #rev_slider_3_1_wrapper{
	margin-bottom:-5% !important;
}

.bhl-about-info{
	padding-bottom: 10px;
}


/* Social Stream styles */
ul#dcsns-filter li a {
	color: $white !important;

}

ul#dcsns-filter li a:hover {
	background-color: $bhl-red !important;

}

.wall-outer .dcsns-toolbar{
	margin: 1em 0 !important;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
	.page-header h1{
		text-align: left;
	}
	.btn-learn{
		text-align: left;
	}
	.btn-learnmore{
		margin: 10px 0px;
	}

	.frontpage-content {
		text-align: left;
	}

	.frontpage-content h3{
		text-align: center;
	}

	.frontpage-widget-left-container, .frontpage-widget-right-container{
		min-height: 300px;

	}

	.frontpage-widget-left, .frontpage-widget-right{
		font-size: $font-size-lrg;
		text-align: left;
	}
	.bhl-contact, .bhl-contact-content {
		text-align: left;
		padding-left: 0;
	}

	.bhl-contact-content-container{
		padding: 0px !important;
	}

	.frontpage-content-banner{

		padding: 4%;
	}

	.bhl-careers-content{
		text-align: left;
	}


}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 
	.frontpage-content{
		font-size: $font-size-sm;
		line-height: 1.5em;
		text-align: left;

	}
	.frontpage-content h3{
		width: 50%;
		display: inline-block;
		vertical-align: middle;
	}


	.frontpage-widget-left-container, .frontpage-widget-right-container{
		min-height: 250px;

	}

	.frontpage-widget-left, .frontpage-widget-right{
		font-size: $font-size-xl;
		text-align: left;
	}


	.btn-learnmore{
		margin: 20px 0px;
	}

	.frontpage-image{
		margin: 0 7% 0 7%;
	}

	.row-even, .row-odd{
		display: table;
  		width: 100%;
	}

	.bhl-year{
		display: table-cell;
  		text-align: center;
  		vertical-align: middle;
  		width: 45%;
	}

	.page-header h1{
		text-align: left;
	}

	.bhl-contact-content-container{
		float: right;	
	}

	.main-bhl-contact{
		padding: 0px 0px 0px 45px;
	}

	.bhl-contact-content{
		text-align: left;
	}


	.bhl-contact-map{
		float: left;
	}

	.bhl-careers-content{
		padding: 0px 45px;
	}

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 
	.frontpage-content{
		
	}
}