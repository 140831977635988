/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Sass Bootstrap */

.navbar{
	margin-bottom: 0px;
	border-bottom: 0px;
}

.navbar-nav{
	margin:0px;
}

.navbar-brand{
	padding-left:20px;

}

.navbar-bhl{
	min-height: 90px;
}

.container > .navbar-header {
	margin-right: 0px;
	margin-left:  0px;
}

.navbar-bhl button{
	padding-top: 20px;
}

.navbar-social-container{
	color: $white;
	background-color: $bhl-blue;
}

.navbar-social > li > a, .social-footer > li > a{
	color: $white;

}


#menu-social-nav, #menu-social-nav-footer {
	text-align: center;
    float:none;
    margin: 0 auto;
    display: table;
    table-layout: fixed;
}

#menu-social-nav > li, #menu-social-nav-footer > li{
	float: left;
}

#menu-social-nav > li > a,  #menu-social-nav-footer > li > a{
	font-size: $font-size-lrg;
}

#menu-social-nav > li > a:hover,  #menu-social-nav-footer > li > a:hover{
	color: $bhl-grey;
	background-color: $bhl-blue;
}




.navbar-toggle .icon-bar {
	background-color: $bhl-grey;
	display: block;
	width: 35px;
	height: 4px;
	border-radius: 5px;
	margin-bottom: 6px;
}

#menu-primary-nav{

}

#menu-primary-nav li > a{
	color: $bhl-blue;
	font-family: 'Montserrat', sans-serif;
	font-weight: $font-weight-bolder;
	font-size: $font-size-sm;
	text-transform: uppercase;
	
}

#menu-primary-nav li > a:hover{
	color: $bhl-blue;
	background-color: $white;
}

li.active a {
	color: $hovergrey !important;
}

 li.active a:hover {
	color: $bhl-blue !important;
}

.even, .odd {
	text-align: right;
	padding-right: 5%;
}

.even {
	background-color: $even;
}

.even a:hover {
	color: $white !important;
	background-color: $even !important;
}

.odd{
	background-color: $odd;
}

.odd a:hover {
	color: $white !important;
	background-color: $odd !important;
}


.mynav.pull-left > li > a {
  color: #777;
  padding-top: 15px;
  padding-bottom: 15px;
}

.mobileNav {
  position: absolute;
  right: 20px;
  top: 0px;
}

.mobileNav > li {
  display: inline-block;
  vertical-align: text-top;
  float: left;
}

.navbar-right ul.dropdown-menu {
  text-align: right;
}

h2.entry-title a{
	color: $darktext !important;
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
}


@media only screen and (max-width: 768px) {
  .mobileNav {
    text-align: right;
  }
  .navbar-nav .open .dropdown-menu {
    border: 1px solid #ccc;
    margin-top: 2px;
    background: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    
    /** Fix position **/
    position: absolute;
    left: auto;
    right: 0;
  }
  .navbar-default .navbar-nav>.open>a,
  .navbar-default .navbar-nav>.open>a:hover,
  .navbar-default .navbar-nav>.open>a:active,
  .navbar-default .navbar-nav>.open>a:focus {
    background: none;
  }
}

.navbar-toggle {
  float: left
}

.navbar-inverse {
  background-color: #337ab7;
  border-color: #337ab7;
}

.navbar-inverse .navbar-brand {
  color: #ffffff;
  white-space: nowrap;
}

/* Override flex-slider controls */
.flex-control-nav{
	bottom: 20px !important;
}
.flex-control-paging li a{
	width: 25px !important;
	height: 25px !important;
	background: rgba(255,255,255, 0.8) !important;
}

.metaslider .flexslider{
	margin-bottom: 0px !important;
}


#bhl-header-img {

  max-height: 700px;	

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;


}


/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
	
	.navbar-social{
		
	} 

	.navbar-social, .social-footer {
		margin: 15px 15% 0px 0px;
	}


	.navbar-social > li > a, .social-footer > li > a{

		font-family: 'Montserrat', sans-serif;
		font-weight: $font-weight-bold;
		font-size: $font-size-lrg;
		text-transform: uppercase;
	}

	.navbar-social > li > a {
		color: $bhl-blue;
	}
	.social-footer > li > a {
		color: $white;
	}

	.navbar-social > li > a:hover{
		color: $bhl-red;
		background-color: $white;
	}

	.social-footer > li > a:hover{
		color: $bhl-grey;
		background-color: $bhl-blue;
	}

	#menu-social-nav-footer{
	 		text-align: center;
		    float:left;
		    margin: 0 auto;
		    display: table;
		    table-layout: fixed;

	 }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 
	.navbar-social {
		margin-right: 0%;
	}
	.mobileNav {
	  position: relative;
	}

	#menu-primary-nav{
		padding-top: 25px;
	}

	#menu-primary-nav li > a{
		color: $bhl-red;
		padding-top: 10px;
		padding-left: 5px;
	}

	#menu-primary-nav li > a:hover{
		color: $hovergrey;
	}

	.even, .odd {
		text-align: left;
		background-color: $white;
		padding-right: 0;
	}

	.even a:hover, .odd a:hover {
		color: $hovergrey !important;
		background-color: $white !important;
	}








}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}