//Breakpoints
$screen-lg-min: 1200px;
$screen-md-min: 992px;
$screen-sm-min: 768px;
$grid-float-breakpoint: $screen-md-min;

//FONTS
// Glyphicons font path
$icon-font-path:        "../fonts/";
$font-stack:   'Monserrat', Arial, sans-serif;
$primary-font: 'Monserrat', sans-serif;


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary: #27ae60;
$bhl-blue:      #0c4a83;
$bhl-red:       #e42834;
$bhl-grey:      #cccccc;
$white:         #ffffff;
$hovergrey:     #707478;
$darkgrey:      #5e5e5e;
$lightgrey:     #e7e7e7; 
$even:          #acacac;
$odd:           #cccccc;
$darktext:      #444444;

$font-size-xxl: 30pt;
$font-size-xl:  26pt;
$font-size-lrg: 22pt;
$font-size-med: 18pt;
$font-size-sm:  14pt;
$font-size-xs:  10pt;

$font-weight-bold:    400;
$font-weight-bolder:  600;
$font-weight-boldest: 700;




/* Standard Media Queries */
/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}

